<template>
  <v-col cols="12">
    <v-card class="pa-2 main-card" outlined>
      <h2>Logovi</h2>
    </v-card>

    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12">
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field
                    v-model="filters.email"
                    label="Email korisnika"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field
                    v-model="filters.memberId"
                    label="SFID člana"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="2">
                  <v-menu
                    :close-on-content-click="true"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filters.timeFrom"
                        prepend-icon="mdi-calendar"
                        label="Vrijeme od"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filters.timeFrom"
                      :max="maxCalendarDate"
                      min="1900-01-01"
                      locale="hr-hr"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="2">
                  <v-menu
                    :close-on-content-click="true"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filters.timeTo"
                        prepend-icon="mdi-calendar"
                        label="Vrijeme do"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filters.timeTo"
                      :max="maxCalendarDate"
                      min="1900-01-01"
                      locale="hr-hr"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="2">
                  <div class="search-member-action">
                    <v-btn
                      v-if="!fetchingData"
                      color="success"
                      class="mr-4"
                      @click="searchLogs"
                    >
                      Traži
                    </v-btn>

                    <div v-else>
                      <v-progress-circular
                        indeterminate
                        color="#1358a3"
                      ></v-progress-circular>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="logs"
      :search="search"
      :loading="fetchingData"
      loading-text="Učitavam logove..."
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Traži..."
          append-icon="mdi-magnify"
          single-line
          class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:[`item.timestamp`]="{ item }">
        {{ formatDateTime(item.timestamp) }}
      </template>
    </v-data-table>

    <div v-show="fetchingData" style="text-align: center">
      <v-progress-circular indeterminate color="#1358a3"></v-progress-circular>
    </div>

    <p v-show="error" class="error-text">{{ error }}</p>
  </v-col>
</template>

<script>
import apiRequest from "../helpers/apiRequest";

export default {
  name: "Logs",

  data() {
    return {
      logs: [],
      filters: {
        email: "",
        memberId: "",
        timeFrom: "",
        timeTo: "",
      },
      search: "",
      fetchingData: false,
      error: false,
    };
  },

  computed: {
    headers() {
      return [
        { text: "Email korisnika", align: "start", value: "email" },
        { text: "Akcija", value: "action_type" },
        { text: "SFID člana", value: "member_salesforce_id" },
        { text: "SFID članarine", value: "opportunity_salesforce_id" },
        { text: "Datum", value: "timestamp" },
      ];
    },

    maxCalendarDate() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },
  },

  methods: {
    async searchLogs() {
      this.error = false;
      this.fetchingData = true;

      try {
        this.logs = await apiRequest("get", "logs/search", this.filters);
      } catch (error) {
        if (error.response) {
          this.error = error.response.data.message;
        } else {
          this.error = error.message;
        }
      } finally {
        this.fetchingData = false;
      }
    },

    formatDateTime(d) {
      const date = new Date(d);

      let datetime = date.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      datetime += ` ${date.toLocaleTimeString()}`;

      return datetime;
    },
  },
};
</script>
