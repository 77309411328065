var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-2 main-card",attrs:{"outlined":""}},[_c('h2',[_vm._v("Logovi")])]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"label":"Email korisnika"},model:{value:(_vm.filters.email),callback:function ($$v) {_vm.$set(_vm.filters, "email", $$v)},expression:"filters.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"label":"SFID člana"},model:{value:(_vm.filters.memberId),callback:function ($$v) {_vm.$set(_vm.filters, "memberId", $$v)},expression:"filters.memberId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","label":"Vrijeme od"},model:{value:(_vm.filters.timeFrom),callback:function ($$v) {_vm.$set(_vm.filters, "timeFrom", $$v)},expression:"filters.timeFrom"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"max":_vm.maxCalendarDate,"min":"1900-01-01","locale":"hr-hr"},model:{value:(_vm.filters.timeFrom),callback:function ($$v) {_vm.$set(_vm.filters, "timeFrom", $$v)},expression:"filters.timeFrom"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","label":"Vrijeme do"},model:{value:(_vm.filters.timeTo),callback:function ($$v) {_vm.$set(_vm.filters, "timeTo", $$v)},expression:"filters.timeTo"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"max":_vm.maxCalendarDate,"min":"1900-01-01","locale":"hr-hr"},model:{value:(_vm.filters.timeTo),callback:function ($$v) {_vm.$set(_vm.filters, "timeTo", $$v)},expression:"filters.timeTo"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"2"}},[_c('div',{staticClass:"search-member-action"},[(!_vm.fetchingData)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success"},on:{"click":_vm.searchLogs}},[_vm._v(" Traži ")]):_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#1358a3"}})],1)],1)])],1)],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.logs,"search":_vm.search,"loading":_vm.fetchingData,"loading-text":"Učitavam logove..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Traži...","append-icon":"mdi-magnify","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.timestamp))+" ")]}}],null,true)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.fetchingData),expression:"fetchingData"}],staticStyle:{"text-align":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#1358a3"}})],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"error-text"},[_vm._v(_vm._s(_vm.error))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }